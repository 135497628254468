import React from 'react'

import './Footer.css'

export default function Footer() {
  return (
    <div className='footer-container'>
      <footer>
        <p>© 2023</p>
      </footer>
    </div>
  )
}
